import React from "react";
import ClassCard from "./ClassCard";
import { Loading, Error } from "./StatusComponents";

export default function ClassesList({ classes, loading, error }) {

  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="classList">
      {loading && <Loading />}
      {error && <Error message={error} />}
      {!loading && !error && (
        <>
          {classes.length > 0 ? (
            classes.map((c) => (
              <ClassCard
                key={c.id}
                classData={c}
                onClick={() => handleClick(c.url)}
              />
            ))
          ) : (
            <div>No classes match your filter criteria.</div>
          )}
        </>
      )}
    </div>
  );
}
