import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";

function ClassCard({ classData, onClick }) {
  const { id, name, image, description, tag, url } = classData;
  const imageUrl = Array.isArray(image) ? image[0] : image;

  return (
    <Card
      className="classItem"
      key={id}
      onClick={() => onClick(url)}
      sx={{
        maxWidth: 360,
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          fontWeight={600}
          color="rgb(68,149,209)"
          padding={1}
          fontFamily={"Roboto"}
        >
          {name}
        </Typography>
        <CardMedia
          component="img"
          image={imageUrl}
          alt={`Preview of ${name}`}
          sx={{
            height: 350, 
            objectFit: 'contain',
            width: '100%',
            marginBottom: 1,
          }}
        />
        <Typography variant="h6" color="InfoText" padding={1} fontFamily={"Roboto"}>
          {description}
          <br />
          {tag}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ClassCard;
