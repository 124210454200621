import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function PaginationComponent({ countPages, currentPage, onChange }) {
  return (
    <Stack spacing={2} justifyContent="center" alignItems="center" margin={"10px"}>
      <Pagination count={countPages} page={currentPage} onChange={onChange} />
    </Stack>
  );
}