import React, { useState, useEffect } from "react";
import Filter from "../components/Filter";
import ClassesList from "../components/ClassesList";
import PaginationComponent from "../components/Pagination";
import Footer from "../components/Footer";
import WelcomeText from "../components/WelcomeText";

function MainPage() {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [classes, setClasses] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [tags, setTags] = useState([]);
    const itemsPerPage = 6; // Adjustable number of items per page

    // Fetch classes data from the server
    useEffect(() => {
        fetch(apiUrl) // Switch this to production URL in .env file
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setClasses(data.classes.class);
                setLoading(false);
                // Extract unique tags from class data for the filter component
                let allTagStrings = data.classes.class.reduce(
                    (acc, c) => acc.concat(c.tags.map((tag) => tag.tag)),
                    []
                );
                setTags([...new Set(allTagStrings.flat())]);
            })
            .catch((error) => {
                setError(error.toString());
                setLoading(false);
            });
    }, [apiUrl]);

    // Handle selection of tags for filtering class list
    const handleTagSelected = (tags) => {
        setSelectedTag(Array.isArray(tags) ? tags : [tags]);
        setCurrentPage(1);
    };

    // Filter classes based on selected tags
    const filteredClasses = classes.filter(
        (classItem) =>
            !selectedTag ||
            selectedTag.every((tag) =>
                classItem.tags.some((tagObj) =>
                    Array.isArray(tagObj.tag)
                        ? tagObj.tag.includes(tag)
                        : tagObj.tag === tag
                )
            )
    );

    // Pagination control
    const countPages = Math.ceil(filteredClasses.length / itemsPerPage);
    const currentClasses = filteredClasses.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <main>
            <WelcomeText />
            <Filter tags={tags} onTagSelected={handleTagSelected} />
            <ClassesList
                classes={currentClasses}
                loading={loading}
                error={error}
                filter={selectedTag}
            />
            {filteredClasses.length > 0 && (
                <PaginationComponent
                    countPages={countPages}
                    currentPage={currentPage}
                    onChange={handleChangePage}
                />
            )}
            <Footer />
        </main>
    );
}

export default MainPage;
