import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function Filter({ tags, onTagSelected }) {

  return (
    <div className="filter-container">
      <Autocomplete
        multiple
        id="tag-select"
        options={tags}
        onChange={(event, value) => onTagSelected(value)}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by tag"
            variant="outlined"
            fullWidth
          />
        )}
      />
    </div>
  );
}
