import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Toolbar, IconButton, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function ResponsiveAppBar() {
  const navigate = useNavigate();

  const pages = [
    {
      title: "Shelly API",
      url: "https://shelly-api-docs.shelly.cloud/",
    },
    {
      title: "Contact Us",
      url: "https://www.shelly.com/en/support-knowledge/contacts",
    },
  ];

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "white" }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, display: { xs: "none", md: "none" }, p: 1 }}
        >
          <MenuIcon />
        </IconButton>
        <Button
          className="logo"
          onClick={() => navigate("/")}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-end",
            ml: 1.5,
          }}
        >
          {pages.map((page) => (
            <Button
              key={page.title}
              onClick={() => window.open(page.url, "_blank")}
              sx={{
                color: "rgb(68, 149, 209)",
                display: "block",
                mr: 4,
                fontWeight: 500,
                fontSize: 16,
                letterSpacing: ".12rem",
              }}
            >
              {page.title}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default ResponsiveAppBar;
